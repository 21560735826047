import request from '../config/request'

export function authLogin (parameter) {
  return request.post('/login/auth', parameter)
}

export function authInfo (parameter) {
  return request.get('/users/islogin', { params: parameter })
}

export function authRegister (parameter) {
  return request.post('/registrasi/akun_sso_mahasiswa?action=create', parameter)
}

export function searchNpm (parameter) {
  return request.post('/registrasi/akun_sso_mahasiswa', parameter)
}
