import request from '../config/request'

export function getMhs (parameter) {
  return request.get('/mahasiswa/row', { params: parameter })
}
export function getSMT (parameter) {
  return request.post('/akademik/smt', parameter)
}

export function putMhs (parameter) {
  return request.post('/mahasiswa/update', parameter)
}

export function getPembimbing (parameter) {
  return request.get('mahasiswa/pembimbing', { params: parameter })
}

export function fotoMahasiswa (parameter) {
  return request.get('/mahasiswa/foto', { params: parameter, responseType: 'blob' })
}

export function uploadFoto (parameter) {
  return request.post('/mahasiswa/upload/foto', parameter, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export function downloadEktm (parameter) {
  return request.get('/download/ektm', { params: parameter, responseType: 'blob' })
}
