<!-- eslint-disable -->
<template>
    <footer class="footer">
      <span>Copyright &copy; 2021 PUSTIK | Universitas Muhammadiyah Metro</span>
    </footer>
</template>
<script>
export default {
  name: 'Footer'
}
</script>
