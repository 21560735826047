import { createRouter, createWebHistory } from 'vue-router'
import storage from 'store'
import store from '../store'
import { Auth, Role } from '../store/mutation'
import { MediaLayout, AdminLayout } from '../layouts'

const routes = [{
  path: '',
  component: AdminLayout,
  redirect: '/dashboard',
  meta: { title: 'Dashboard' },
  children: [
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ '../views/mod_dashboard/Dashboard.vue'),
      meta: { title: 'Dashboard', subTitle: 'Halaman dashboard sistem informasi akademik', permission: 'dashboard', subMenu: '', keyMenu: '0.01' }
    },
    {
      path: '/editbiodata',
      name: 'editbiodata',
      component: () => import(/* webpackChunkName: "editbiodata" */ '../views/mod_biodata/BiodataEdit.vue'),
      meta: { title: 'Pemutakhiran Data', subTitle: 'Halaman pemutakhiran data pokok mahasiswa', permission: 'editbiodata', subMenu: '', keyMenu: '0.02' }
    },
    {
      path: '/editprofil',
      name: 'editprofil',
      component: () => import(/* webpackChunkName: "editprofil" */ '../views/mod_biodata/BiodataFoto.vue'),
      meta: { title: 'Profil Mahasiswa', subTitle: 'Halaman perubahan data profil mahasiswa', permission: 'editprofil', subMenu: '', keyMenu: '0.03' }
    },
    {
      path: '/entrikrs',
      name: 'entrikrs',
      component: () => import(/* webpackChunkName: "entrikrs" */ '../views/mod_krs/EntriKrs.vue'),
      meta: { title: 'Entri KRS', subTitle: 'Halaman entri karturencana studi', permission: 'entrikrs', subMenu: '', keyMenu: '1.01' }
    },
    {
      path: '/krs',
      name: 'riwayatkrs',
      component: () => import(/* webpackChunkName: "riwayatkrs" */ '../views/mod_krs/RiwayatKrs.vue'),
      meta: { title: 'Riwayat KRS', subTitle: 'Halaman riwayat kartu rencana studi', permission: 'riwayatkrs', subMenu: '', keyMenu: '1.02' }
    },
    {
      path: '/kpu',
      name: 'kpu',
      component: () => import(/* webpackChunkName: "kpu" */ '../views/mod_krs/Kpu.vue'),
      meta: { title: 'Kartu Peserta Ujian ( K P U )', subTitle: 'Halaman cetak kartu perserta ujian', permission: 'kpu', subMenu: '', keyMenu: '0.02' }
    },
    {
      path: '/khs',
      name: 'khs',
      component: () => import(/* webpackChunkName: "khs" */ '../views/mod_nilai/RiwayatKhs.vue'),
      meta: { title: 'Kartu Hasil Studi', subTitle: 'Halaman kartu hasil studi', permission: 'khs', subMenu: '', keyMenu: '2.01' }
    },
    {
      path: '/transkip',
      name: 'transkip',
      component: () => import(/* webpackChunkName: "transkip" */ '../views/mod_nilai/Transkip.vue'),
      meta: { title: 'Transkip Akademik', subTitle: 'Halaman cetak transkip akademik', permission: 'transkip', subMenu: '', keyMenu: '2.02' }
    },
    {
      path: '/edom/matakuliah',
      name: 'edom',
      component: () => import(/* webpackChunkName: "edom" */ '../views/mod_edom/MatkulDosen.vue'),
      meta: { title: 'Evaluasi Dosen Oleh Mahasiswa', subTitle: 'Halaman evaluasi dosen oleh mahasiswa', permission: 'edom', subMenu: '', keyMenu: '2.03' }
    },
    {
      path: '/edom/kuisoner/:id',
      name: 'edominput',
      component: () => import(/* webpackChunkName: "edom" */ '../views/mod_edom/KuisEdom.vue'),
      meta: { title: 'Evaluasi Dosen Oleh Mahasiswa', subTitle: 'Halaman evaluasi dosen oleh mahasiswa', permission: 'edominput', subMenu: '', keyMenu: '2.03.01' }
    },
    {
      path: '/info-akun-spada',
      name: 'akunspada',
      component: () => import(/* webpackChunkName: "akunspada" */ '../views/mod_spada/SpadaInfoAkun.vue'),
      meta: { title: 'Akun SPADA', subTitle: 'Halaman pembuatan akun spada', permission: 'akunspada', subMenu: '', keyMenu: '3.01' }
    },
    {
      path: '/registrasi-akun-spada',
      name: 'registerakunspada',
      component: () => import(/* webpackChunkName: "registerakunspada" */ '../views/mod_spada/SpadaRegistrasiAkun.vue'),
      meta: { title: 'Akun SPADA', subTitle: 'Halaman pembuatan akun spada', permission: 'registerakunspada', subMenu: '', keyMenu: '3.01.01' }
    },
    {
      path: '/list-course-spada',
      name: 'coursespada',
      component: () => import(/* webpackChunkName: "coursespada" */ '../views/mod_spada/SpadaListCourse.vue'),
      meta: { title: 'Course SPADA', subTitle: 'Halaman enroll course spada', permission: 'coursespada', subMenu: '', keyMenu: '3.02' }
    },
    {
      path: '/info-course-spada/:id/:kode',
      name: 'infocoursespada',
      component: () => import(/* webpackChunkName: "coursespada" */ '../views/mod_spada/SpadaInfoCourse.vue'),
      meta: { title: 'Course SPADA', subTitle: 'Halaman enroll course spada', permission: 'infocoursespada', subMenu: '', keyMenu: '3.02.01' }
    },
    {
      path: '/keuangan/saldokip',
      name: 'saldokip',
      component: () => import(/* webpackChunkName: "saldokip" */ '../views/mod_pembayaran/BeasiswaKIP.vue'),
      meta: { title: 'Saldo KIP', subTitle: 'Halaman informasi saldo KIP', permission: 'saldokip', subMenu: '', keyMenu: '4.01' }
    },
    {
      path: '/keuangan/tagihan',
      name: 'infotagihan',
      component: () => import(/* webpackChunkName: "infotagihan" */ '../views/mod_pembayaran/TagihanInfo.vue'),
      meta: { title: 'Tagihan Akademik', subTitle: 'Halaman informasi tagihan akademik', permission: 'infotagihan', subMenu: '', keyMenu: '4.02' }
    },
    {
      path: '/keuangan/tagihan/payment-kip',
      name: 'paymentkip',
      component: () => import(/* webpackChunkName: "infotagihan" */ '../views/mod_pembayaran/TransaksiKIP.vue'),
      meta: { title: 'Pembayaran KIP', subTitle: 'Halaman pembayaran tagihan', permission: 'paymentkip', subMenu: '', keyMenu: '4.02.01' }
    },
    {
      path: '/keuangan/tagihan/slip-bmi',
      name: 'slipbmi',
      component: () => import(/* webpackChunkName: "slipbmi" */ '../views/mod_pembayaran/SlipBankBMI.vue'),
      meta: { title: 'Slip Bank BMI', subTitle: 'Halaman cetak slip tagihan bank BMI', permission: 'slipbmi', subMenu: '', keyMenu: '4.02.02' }
    },
    {
      path: '/keuangan/tagihan/slip-bmm',
      name: 'slipbmm',
      component: () => import(/* webpackChunkName: "slipbmm" */ '../views/mod_pembayaran/SlipBankBMM.vue'),
      meta: { title: 'Slip Bank BMM', subTitle: 'Halaman cetak slip tagihan bank BMM', permission: 'slipbmm', subMenu: '', keyMenu: '4.02.03' }
    },
    {
      path: '/keuangan/tagihan/slip-bsi',
      name: 'slipbsi',
      component: () => import(/* webpackChunkName: "slipbsi" */ '../views/mod_pembayaran/SlipBankBSI.vue'),
      meta: { title: 'Slip Bank BSI', subTitle: 'Halaman cetak slip tagihan bank BSI', permission: 'slipbsi', subMenu: '', keyMenu: '4.02.04' }
    },
    {
      path: '/keuangan/buat-tagihan',
      name: 'buattagihan',
      component: () => import(/* webpackChunkName: "buattagihan" */ '../views/mod_pembayaran/TagihanAdd.vue'),
      meta: { title: 'Pembuatan Tagihan', subTitle: 'Halaman pembuatan tagihan akademik', permission: 'buattagihan', subMenu: '', keyMenu: '4.03' }
    },
    {
      path: '/prestasi',
      name: 'prestasi',
      component: () => import(/* webpackChunkName: "prestasi" */ '../views/mod_prestasi/Prestasi.vue'),
      meta: { title: 'Prestasi Mahasiswa', subTitle: 'Halaman riwayat prestasi mahasiswa', permission: 'prestasi', subMenu: '', keyMenu: '4.03' }
    },
    {
      path: '/prestasi/unggah',
      name: 'inputprestasi',
      component: () => import(/* webpackChunkName: "inputprestasi" */ '../views/mod_prestasi/PrestasiAdd.vue'),
      meta: { title: 'Prestasi Mahasiswa', subTitle: 'Halaman tambah prestasi mahasiswa', permission: 'prestasi', subMenu: '', keyMenu: '4.03' }
    }]
},
{
  path: '/media',
  component: MediaLayout,
  redirect: '/media/login',
  meta: { title: 'Login' },
  children: [{
    path: 'login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/mod_login/Login.vue'),
    meta: { title: 'Login', subTitle: 'Halaman login siakad', permission: 'login', subMenu: '', keyMenu: '0' }
  },
  {
    path: 'registerakun',
    name: 'registerakun',
    component: () => import(/* webpackChunkName: "login" */ '../views/mod_login/CreateAkun.vue'),
    meta: { title: 'Login', subTitle: 'Halaman registrasi akun', permission: 'registerakun', subMenu: '', keyMenu: '0' }
  }
  ]
}]
/*
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })

  router.beforeEach((to, from, next) => {
    const auth = JSON.parse(store.getters.userData)
    // let authRoles = (auth !== null) ? auth.roles.map(role => role.name) : ['role'];
    // redirect to login page if not logged in and trying to access a restricted page
    const authRoles = (auth !== null) ? auth.roles[0] : ''
    const { authorize } = to.meta

    if (authorize) {
      if (!authRoles) {
        // not logged in so redirect to login page with the return url
        return next({ path: '/login' })
      }
      // check if route is restricted by role
      if (authorize.length && !authorize.includes(authRoles)) {
        // role not authorised so redirect to home page
        return next({ path: '/login' })
      }
      const mhs = JSON.parse(store.getters.mhs)
      const isBiodata = (mhs.updated_at !== null)
      if (!isBiodata && to.name !== 'RouteEditBiodata') {
        return next({ path: '/editbiodata' })
      }
    }

    next()
  })
*/

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const allowList = ['login', 'registerakun'] // no redirect allowList
const loginRoutePath = '/media/login'
const defaultRoutePath = '/dashboard'
const pemutakhiranPath = '/editbiodata'

router.beforeEach((to, from, next) => {
  /* has token */
  if (storage.get(Auth)) {
    if (to.path === loginRoutePath) {
      next({ path: defaultRoutePath })
    } else {
      // check login user.roles is null
      const roles = store.getters.roles
      const mhs = store.getters.user
      if (roles.length === 0) {
        // request login userInfo
        const params = { roles: storage.get(Role) }
        store
          .dispatch('Logged', params)
          .then(res => {
            const roles = res && res.roles
            if (!roles) {
              next({ path: loginRoutePath, query: { redirect: to.fullPath } })
            }
            const mhs = store.getters.user
            const isBiodata = mhs.updated_at === null
            console.log(isBiodata)
            if (isBiodata) {
              next({ path: pemutakhiranPath })
            }
            // generate dynamic router
            const redirect = decodeURIComponent(from.query.redirect || to.path)
            if (to.path === redirect) {
              next({ ...to, replace: true })
            } else {
              next({ path: redirect })
            }
          })
          .catch(() => {
            store.dispatch('Logout').then(() => {
              next({ path: loginRoutePath, query: { redirect: to.fullPath } })
            })
          })
      } else {
        const isBiodata = mhs.updated_at === null
        if (to.path !== pemutakhiranPath && isBiodata) {
          next({ path: pemutakhiranPath })
        }
        const isNext = store.getters.menus
        if (!isNext.includes(to.meta.keyMenu)) {
          next({ path: defaultRoutePath })
        }
        next()
      }
    }
  } else {
    if (allowList.includes(to.name)) {
      next()
    } else {
      next({ path: loginRoutePath, query: { redirect: to.fullPath } })
    }
  }
})

export default router
