import request from './request'
import storage from 'store'
import {
  Auth
} from '../store/mutation'

const setup = (store) => {
  request.interceptors.request.use(
    (config) => {
      const Authoriz = storage.get(Auth)
      if (Authoriz) {
        config.headers.Authorization = Authoriz
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

export default setup
