export const Auth = 'Authorization'
export const Role = 'Role'
export const ApiKey = 'NL2Q4OMZT2ISF8C'
export const User = {
  id_reg_pd: '',
  id_prodi: '',
  nama_lengkap: '',
  nama_prodi: '',
  user_id: '',
  user_email: '',
  username: '',
  updated_at: ''
}

export const Navigation = [{
  keys: '0.01',
  name: 'dashboard',
  visibel: false
},
{
  keys: '0.02',
  name: 'editbiodata',
  visibel: false
},
{
  keys: '0.03',
  name: 'editprofil',
  visibel: false
},
{
  keys: '1.01',
  name: 'entrikrs',
  visibel: false
},
{
  keys: '1.02',
  name: 'riwayatkrs',
  visibel: false
},
{
  keys: '0.02',
  name: 'kpu',
  visibel: false
},
{
  keys: '2.01',
  name: 'khs',
  visibel: false
},
{
  keys: '2.02',
  name: 'transkip',
  visibel: false
},
{
  keys: '2.03',
  name: 'edom',
  visibel: false
},
{
  keys: '2.03.01',
  name: 'edominput',
  visibel: false
},
{
  keys: '3.01',
  name: 'akunspada',
  visibel: false
},
{
  keys: '3.01.01',
  name: 'registerakunspada',
  visibel: false
},
{
  keys: '3.02',
  name: 'coursespada',
  visibel: false
},
{
  keys: '3.02.01',
  name: 'infocoursespada',
  visibel: false
},
{
  keys: '4.01',
  name: 'saldokip',
  visibel: false
},
{
  keys: '4.02',
  name: 'infotagihan',
  visibel: false
},
{
  keys: '4.02.01',
  name: 'paymentkip',
  visibel: false
},
{
  keys: '4.02.02',
  name: 'slipbmi',
  visibel: false
},
{
  keys: '4.02.03',
  name: 'slipbmm',
  visibel: false
},
{
  keys: '4.02.04',
  name: 'slipbsi',
  visibel: false
},
{
  keys: '4.03',
  name: 'buattagihan',
  visibel: false
}]
