<!-- eslint-disable -->
<template>
  <div class="top-bar primary-top-bar">
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <router-link to="/" class="admin-logo">
            <h1>
              <img alt="" src="/img/icon.png" class="logo-icon margin-r-10 rounded-circle">
            </h1>
            <span class="toggle-none hidden-xs logo-text1">SIAKAD UM METRO</span>
          </router-link>
          <div class="left-nav-toggle">
            <a href="#" class="nav-collapse"><i class="fa fa-bars"></i></a>
          </div>
          <div class="left-nav-collapsed" >
							<a  href="#" class="nav-collapsed"><i class="fa fa-bars"></i></a>
						</div>
          <!--
          <div class="search-form hidden-xs">
            <form>
              <input class="form-control" placeholder="Search for..." type="text"> <button class="btn-search"
                type="button">
                <i class="fa fa-search"></i>
              </button>
            </form>
          </div>
          -->
        </div>
        <div class="col">
          <ul class="list-inline top-right-nav">
            <li class="dropdown icons-dropdown d-none-m">
              <a class="dropdown-toggle" data-toggle="dropdown" href="#"><i class="fa fa-envelope"></i>
                <div class="notify setpos"> <span class="heartbit"></span> <span class="point"></span>
                </div>
              </a>
              <ul class="dropdown-menu top-dropdown lg-dropdown notification-dropdown">
                <li>
                  <div class="dropdown-header">
                    <a class="float-right" href="#"><small>View All</small></a> Messages
                  </div>
                  <!--
                  <div class="scrollDiv">
                    <div class="notification-list">
                      <a
                        class="clearfix"
                        href="javascript:%20void(0);"
                      >
                        <span class="notification-icon">
                          <img
                            alt=""
                            class="rounded-circle"
                            src="/img/avtar-2.png"
                            width="50"
                          >
                        </span>
                        <span class="notification-title">
                          John Doe
                          <label class="label label-warning float-right">Support</label>
                        </span>
                        <span class="notification-description">Lorem Ipsum is simply dummy text
                          of the printing.</span>
                        <span class="notification-time">15 minutes ago</span>
                      </a>

                      <a
                        class="clearfix"
                        href="javascript:%20void(0);"
                      >
                        <span class="notification-icon">
                          <img
                            alt=""
                            class="rounded-circle"
                            src="/img/avtar-3.png"
                            width="50"
                          >
                        </span>
                        <span class="notification-title">
                          Govindo Doe
                          <label class="label label-warning float-right">Support</label>
                        </span>
                        <span class="notification-description">Lorem Ipsum is simply dummy text
                          of the printing.</span>
                        <span class="notification-time">15 minutes ago</span>
                      </a>

                      <a
                        class="clearfix"
                        href="javascript:%20void(0);"
                      >
                        <span class="notification-icon">
                          <img
                            alt=""
                            class="rounded-circle"
                            src="/img/avtar-4.png"
                            width="50"
                          >
                        </span>
                        <span class="notification-title">
                          Megan Doe
                          <label class="label label-warning float-right">Support</label>
                        </span>
                        <span class="notification-description">Lorem Ipsum is simply dummy text
                          of the printing.</span>
                        <span class="notification-time">15 minutes ago</span>
                      </a>

                      <a
                        class="clearfix"
                        href="javascript:%20void(0);"
                      >
                        <span class="notification-icon">
                          <img
                            alt=""
                            class="rounded-circle"
                            src="/img/avtar-5.png"
                            width="50"
                          >
                        </span>
                        <span class="notification-title">
                          Hritik Doe
                          <label class="label label-warning float-right">Support</label>
                        </span>
                        <span class="notification-description">Lorem Ipsum is simply dummy text
                          of the printing.</span>
                        <span class="notification-time">15 minutes ago</span>
                      </a>
                    </div>
                  </div>
                  -->
                </li>
              </ul>
            </li>
            <li class="dropdown icons-dropdown d-none-m">
              <a class="dropdown-toggle" data-toggle="dropdown" href="#"><i class="fa fa-bell"></i>
                <div class="notify setpos"> <span class="heartbit"></span> <span class="point"></span>
                </div>
              </a>
              <ul class="dropdown-menu top-dropdown lg-dropdown notification-dropdown">
                <li>
                  <div class="dropdown-header">
                    <a class="float-right" href="#"><small>View All</small></a> Notifications
                  </div>
                  <!--
                  <div class="scrollDiv">
                    <div class="notification-list">
                      <a
                        class="clearfix"
                        href="javascript:%20void(0);"
                      >
                        <span class="notification-icon">
                          <i class="icon-cloud-upload text-primary"></i>
                        </span>
                        <span class="notification-title">Upload Complete</span>
                        <span class="notification-description">Lorem Ipsum is simply dummy text
                          of the printing.</span>
                        <span class="notification-time">15 minutes ago</span>
                      </a>

                      <a
                        class="clearfix"
                        href="javascript:%20void(0);"
                      >
                        <span class="notification-icon">
                          <i class="icon-info text-warning"></i>
                        </span>
                        <span class="notification-title">Storage Space low</span>
                        <span class="notification-description">Lorem Ipsum is simply dummy text
                          of the printing.</span>
                        <span class="notification-time">15 minutes ago</span>
                      </a>

                      <a
                        class="clearfix"
                        href="javascript:%20void(0);"
                      >
                        <span class="notification-icon">
                          <i class="icon-check text-success"></i>
                        </span>
                        <span class="notification-title">Project Task Complete</span>
                        <span class="notification-description">Lorem Ipsum is simply dummy text
                          of the printing.</span>
                        <span class="notification-time">15 minutes ago</span>
                      </a>

                      <a
                        class="clearfix"
                        href="javascript:%20void(0);"
                      >
                        <span class="notification-icon">
                          <i class=" icon-graph text-danger"></i>
                        </span>
                        <span class="notification-title">CPU Usage</span>
                        <span class="notification-description">Lorem Ipsum is simply dummy text
                          of the printing.</span>
                        <span class="notification-time">15 minutes ago</span>
                      </a>
                    </div>
                  </div>
                  -->
                </li>
              </ul>
            </li>
            <!--
            <li class="dropdown d-none-m">
              <a class="right-sidebar-toggle" href="javascript:%20void(0);"><i class="fa fa-align-right"></i></a>
            </li>
            -->
            <li class="dropdown avtar-dropdown">
              <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                <img alt="" class="rounded-circle" src="/img/avtar-2.png" width="30">
                {{NamaPengguna}}
              </a>
              <ul class="dropdown-menu top-dropdown">
                <li>
                  <router-link to="/editprofil" class="dropdown-item"><i class="icon-user"></i> Profile</router-link>
                </li>
                <li>
                  <router-link to="/editbiodata" class="dropdown-item"><i class="icon-settings"></i>Settings</router-link>
                </li>
                <li class="dropdown-divider"></li>
                <li>
                  <a class="dropdown-item" href="javascript:%20void(0);" @click="logout"><i class="icon-logout"></i>
                    Logout</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="nav-top-bar">
  <ul class="list-inline top-right-nav">
    <li class="dropdown icons-dropdown d-none-m">
      <a class="dropdown-toggle" data-toggle="dropdown" href="#"><i class="fa fa-envelope"></i>
        <div class="notify setpos"> <span class="heartbit"></span> <span class="point"></span>
        </div>
      </a>
      <ul class="dropdown-menu top-dropdown lg-dropdown notification-dropdown">
        <li>
          <div class="dropdown-header">
            <a class="float-right" href="#"><small>View All</small></a> Messages
          </div>
        </li>
      </ul>
    </li>
    <li class="dropdown icons-dropdown d-none-m">
      <a class="dropdown-toggle" data-toggle="dropdown" href="#"><i class="fa fa-bell"></i>
        <div class="notify setpos"> <span class="heartbit"></span> <span class="point"></span>
        </div>
      </a>
      <ul class="dropdown-menu top-dropdown lg-dropdown notification-dropdown">
        <li>
          <div class="dropdown-header">
            <a class="float-right" href="#"><small>View All</small></a> Notifications
          </div>

        </li>
      </ul>
    </li>
    <li class="dropdown avtar-dropdown">
      <a class="dropdown-toggle" data-toggle="dropdown" href="#">
        <img alt="" class="rounded-circle" :src="fotoUrl" width="30">
        {{NamaPengguna}}
      </a>
      <ul class="dropdown-menu top-dropdown">
        <li>
          <router-link to="/editprofil" class="dropdown-item"><i class="icon-user"></i> Profile</router-link>
        </li>
        <li>
          <router-link to="/editbiodata" class="dropdown-item"><i class="icon-settings"></i> Settings</router-link>
        </li>
        <li class="dropdown-divider"></li>
        <li>
          <a class="dropdown-item" href="javascript:%20void(0);" @click="logout"><i class="icon-logout"></i>
            Logout</a>
        </li>
      </ul>
    </li>
  </ul>
  </div>
</template>

<script>
/* global $ */
import { fotoMahasiswa } from '@/services/mahasiswa.service'
import { useStore } from 'vuex'
export default {
  name: 'PrimaryTopBar',
  emits: ['handle-logout'],
  data () {
    const store = useStore()
    const users = store.getters.user
    const namaMhs = users?.nama_lengkap || ''

    return {
      userID: users.id_reg_pd,
      fotoUrl: '/img/avtar-2.png',
      NamaPengguna: namaMhs
    }
  },
  mounted () {
    $('.left-nav-toggle a').on('click', function (event) {
      event.preventDefault()
      $('body').toggleClass('nav-toggle')
    })
    $('.left-nav-collapsed a').on('click', function (event) {
      event.preventDefault()
      $('body').toggleClass('nav-collapsed')
    })
  },
  created () {
    this.handleFoto()
  },
  methods: {
    async logout () {
      this.$emit('handle-logout')
    },
    handleFoto () {
      fotoMahasiswa({ reg: this.userID })
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response]))
          this.fotoUrl = url
        })
        .catch((error) => {
          this.errorMsg = (error.response) ? {} : {}
        })
    }
  }
}
</script>
