import { authLogin, authInfo, searchNpm, authRegister } from '../../services/auth.service'
import { Auth, Role, Navigation, User } from '../mutation'
import setRoles from '@/config/permission'
import storage from 'store'
const auth = {
  state: {
    token: 'NULL',
    roles: [],
    menus: [],
    user: {}
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_MENUS: (state, menus) => {
      state.menus = menus
    },
    SET_USER: (state, user) => {
      state.user = user
    }
  },
  actions: {
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        authLogin(userInfo).then(response => {
          if (response.status !== 200) {
            reject(new Error('authLogin: Akun tidak terdaftar !'))
          } else {
            console.log('rolessss', response)
            const result = response.records
            const isRole = result.roles[0]
            const isMenu = Navigation.map(menu => {
              const setMenu = setRoles[isRole]
              const visibel = setMenu.includes(menu.name)
              if (visibel) {
                return menu.keys
              }
            })

            User.user_id = result.id_user
            User.nama_lengkap = result.nama_lengkap
            User.username = result.username
            User.pegawai_id = ''
            User.updated_at = null

            /* storage.set(Auth, result.token, 7 * 24 * 60 * 60 * 1000) */
            const nameRole = (result.roles[0] === 'siakadmhs') ? 'mahasiswa' : result.roles[0]
            storage.set(Auth, result.token)
            storage.set(Role, nameRole)

            commit('SET_TOKEN', result.token)
            commit('SET_ROLES', result.roles)
            commit('SET_MENUS', isMenu)
            commit('SET_USER', User)

            resolve(response.records)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    Logged ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        authInfo(userInfo).then(response => {
          if (response.status !== 200) {
            reject(new Error('authLogin: Akun tidak terdaftar !'))
          } else {
            const result = response.records
            const isRole = result.roles[0]
            const isMenu = Navigation.map(menu => {
              const setMenu = setRoles[isRole]
              const visibel = setMenu.includes(menu.name)
              if (visibel) {
                return menu.keys
              }
            })

            User.user_id = result.id_user
            User.nama_lengkap = result.nama_lengkap
            User.username = result.username

            if (userInfo.roles === 'mahasiswa') {
              User.id_reg_pd = result.mahasiswa.id_reg_pd
              User.id_prodi = result.mahasiswa.id_sms
              User.id_smt_masuk = result.mahasiswa.tahun_masuk
              User.nama_prodi = result.mahasiswa.nama_prodi
              User.user_email = result.mahasiswa.alamat_email
              User.updated_at = result.mahasiswa.updated_at
            }

            const roleMenu = isMenu.filter(Boolean)

            commit('SET_TOKEN', result.token)
            commit('SET_ROLES', result.roles)
            commit('SET_MENUS', roleMenu)
            commit('SET_USER', User)
            resolve(response.records)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    SearchNpm ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        searchNpm(userInfo).then(response => {
          if (response.status !== 200) {
            reject(new Error(response.messages))
          } else {
            resolve(response.records)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    RegistrasiAkun ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        authRegister(userInfo).then(response => {
          if (response.status !== 200) {
            reject(new Error(response.messages))
          } else {
            resolve(response.records)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    Logout ({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        storage.remove(Auth)
        resolve()
      })
    }
  }
}
export default auth // eslint-disable-next-line
