<!-- eslint-disable -->
<template>
  <nav id="right-sidebar-toggle" class="toggle-sidebar">
    <div class="nano">
      <div class="nano-content">
        <div>
          <ul class="list-inline nav-tab-card clearfix" role="tablist">
            <li class="active" role="presentation">
              <a aria-controls="friends" data-toggle="tab" href="#friends" role="tab">Pengguna</a>
            </li>
          </ul>
          <div class="tab-content">
            <div id="friends" class="tab-pane active" role="tabcard">
              <!--
              <ul class="list-unstyled sidebar-contact-list">
                <li class="clearfix">
                  <a
                    class="media-box"
                    href="#"
                  ><span class="float-right"><span
                     class="circle circle-success circle-lg"
                   ></span></span> <span class="float-left">
                     <img
                       alt="user"
                       class="media-box-object rounded-circle"
                       src="img/avtar-2.png"
                       width="50"
                     ></span>
                    <span class="media-box-body"><span class="media-box-heading"><strong>John Doe</strong><br>
                      <small class="text-muted">Designer</small></span></span></a>
                </li>
                <li class="clearfix">
                  <a
                    class="media-box"
                    href="#"
                  ><span class="float-right"><span
                     class="circle circle-success circle-lg"
                   ></span></span> <span class="float-left">
                     <img
                       alt="user"
                       class="media-box-object rounded-circle"
                       src="img/avtar-1.png"
                       width="50"
                     ></span>
                    <span class="media-box-body"><span class="media-box-heading"><strong>Govinda Doe</strong><br>
                      <small class="text-muted">Designer</small></span></span></a>
                </li>
                <li class="clearfix">
                  <a
                    class="media-box"
                    href="#"
                  ><span class="float-right"><span
                     class="circle circle-danger circle-lg"
                   ></span></span> <span class="float-left">
                     <img
                       alt="user"
                       class="media-box-object rounded-circle"
                       src="img/avtar-3.png"
                       width="50"
                     ></span>
                    <span class="media-box-body"><span class="media-box-heading"><strong>Megan Doe</strong><br>
                      <small class="text-muted">Designer</small></span></span></a>
                </li>
                <li class="clearfix">
                  <a
                    class="media-box"
                    href="#"
                  ><span class="float-right"><span
                     class="circle circle-success circle-lg"
                   ></span></span> <span class="float-left">
                     <img
                       alt="user"
                       class="media-box-object rounded-circle"
                       src="img/avtar-4.png"
                       width="50"
                     ></span>
                    <span class="media-box-body"><span class="media-box-heading"><strong>Hritik Doe</strong><br>
                      <small class="text-muted">Designer</small></span></span></a>
                </li>
                <li class="clearfix">
                  <a
                    class="media-box"
                    href="#"
                  ><span class="float-right"><span
                     class="circle circle-success circle-lg"
                   ></span></span> <span class="float-left">
                     <img
                       alt="user"
                       class="media-box-object rounded-circle"
                       src="img/avtar-5.png"
                       width="50"
                     ></span>
                    <span class="media-box-body"><span class="media-box-heading"><strong>Bianca Doe</strong><br>
                      <small class="text-muted">Designer</small></span></span></a>
                </li>
                <li class="clearfix">
                  <a
                    class="media-box"
                    href="#"
                  ><span class="float-right"><span
                     class="circle circle-success circle-lg"
                   ></span></span> <span class="float-left">
                     <img
                       alt="user"
                       class="media-box-object rounded-circle"
                       src="img/avtar-6.png"
                       width="50"
                     ></span>
                    <span class="media-box-body"><span class="media-box-heading"><strong>John Doe</strong><br>
                      <small class="text-muted">Designer</small></span></span></a>
                </li>
                <li class="clearfix">
                  <a
                    class="media-box"
                    href="#"
                  ><span class="float-right"><span
                     class="circle circle-success circle-lg"
                   ></span></span> <span class="float-left">
                     <img
                       alt="user"
                       class="media-box-object rounded-circle"
                       src="img/avtar-1.png"
                       width="50"
                     ></span>
                    <span class="media-box-body"><span class="media-box-heading"><strong>Govinda Doe</strong><br>
                      <small class="text-muted">Designer</small></span></span></a>
                </li>
                <li class="clearfix">
                  <a
                    class="media-box"
                    href="#"
                  ><span class="float-right"><span
                     class="circle circle-danger circle-lg"
                   ></span></span> <span class="float-left">
                     <img
                       alt="user"
                       class="media-box-object rounded-circle"
                       src="img/avtar-2.png"
                       width="50"
                     ></span>
                    <span class="media-box-body"><span class="media-box-heading"><strong>Megan Doe</strong><br>
                      <small class="text-muted">Designer</small></span></span></a>
                </li>
                <li>
                  <div class=" text-center">
                    <a
                      class="btn btn-teal"
                      href="javascript:void(0);"
                      title="See more contacts"
                    >Load more..</a>
                  </div>
                </li>
              </ul>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
