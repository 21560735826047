import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import numeral from 'numeral'
import interceptors from './config/interceptors'
import '@/assets/lib/jasny-bootstrap/css/jasny-bootstrap.min.css'
import '@/assets/lib/bootstrap/css/bootstrap.min.css'
import '@/assets/lib/sweet-alerts2/sweetalert2.min.css'
import '@/assets/lib/toast/jquery.toast.min.css'
import '@/assets/lib/datatables/jquery.dataTables.min.css'
import '@/assets/lib/bootstrap-datepicker/css/bootstrap-datepicker.min.css'
import '@/assets/lib/boostrap-select2/css/select2.min.css'
import '@/assets/lib/boostrap-select2/css/select2-bootstrap4.min.css'
import '@/assets/css/style.css'
import 'vue-loading-overlay/dist/vue-loading.css'

interceptors(store)
const app = createApp(App)

app.config.globalProperties.$filters = {
  formatRp (value) {
    return numeral(value).format('0,0')
  },
  formatNumber (value) {
    return numeral(value)
  }
}
app
  .use(store)
  .use(router)
  .mount('#app')
