<!-- eslint-disable -->
<template>
  <primary-top-bar @handle-logout="handleLogout"></primary-top-bar>
  <SidebarRight />
  <SidebarLeft nav-item="Dashboard" />
  <div class="row page-header page-header-admin">
    <div class="col-lg-8 align-self-center ">
      <h2>{{ routeMeta.title.toUpperCase() }}</h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item active">Sistem Informasi Akademik</li>
        <li class="breadcrumb-item active">{{ routeMeta.subTitle }}</li>
      </ol>
    </div>
  </div>
  <section class="main-content">
    <router-view />
    <Footer />
  </section>
</template>
<script>
import PrimaryTopBar from '@/components/layout/PrimaryTopBar.vue'
import SidebarRight from '@/components/layout/SidebarRight.vue'
import SidebarLeft from '@/components/layout/SidebarLeft.vue'
import Footer from '@/components/layout/Footer.vue'
import { useRoute } from 'vue-router'
import { defineComponent, computed } from 'vue'
import { mapActions } from 'vuex'
export default defineComponent({
  components: {
    PrimaryTopBar,
    SidebarRight,
    SidebarLeft,
    Footer
  },
  setup () {
    const routeMeta = computed(() => {
      return useRoute().meta
    })
    return { routeMeta }
  },
  methods: {
    ...mapActions([
      'Logout'
    ]),
    handleLogout () {
      this.Logout()
        .then((res) => {
          this.$router.push({ path: '/media/login' })
        })
    }
  }
})
</script>

<style>
body {
  padding-top: 0px !important;
  position: inherit !important;
}
.bg-login {
  /* The image used */
  background-image: url("/img/world-map.png");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
}
</style>
