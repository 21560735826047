
import axios from 'axios'
import {
  ApiKey
} from '../store/mutation'

const request = axios.create({
  /* baseURL: process.env.VUE_APP_ROOT_API, */
  /* baseURL: 'https://dev-servicepgsql.ummetro.ac.id', */
  baseURL: 'https://servicepgsql.ummetro.ac.id',
  headers: {
    'Content-Type': 'application/json'
  }
})

const errorHandler = (error) => {
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  config.headers['X-API-KEY'] = ApiKey
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

export default request
